import React from "react";

function Query() {
	return (
		<div className="relative overflow-hidden my-[5%] flex flex-col justify-start align-start items-start md:px-[10%] px-[2%]">
			<div className="rounded-[16px] bg-[#071114] w-full  px-[10px] lg:px-[105px] xl:px-[120px] py-[30px] lg:py-[50px] xl:py-[60px] flex flex-col justify-center items-center gap-10">
				<p className="text-white text-center font-inter text-lg font-bold leading-normal capitalize">
					Contact us today and let's craft your extraordinary living story together.
				</p>
				<p className="text-white text-center font-inter text-base md:text-lg font-light leading-normal">
					Reach out, and let's turn your dreams into an address. Your journey to exceptional living
					starts with a simple 'Hello'!
				</p>
				<div className="rounded-full w-full lg:w-[70%] px-[10px] md:py-[15px] py-[5px] bg-white flex flex-row justify-center ">
					<input
						className="rounded-full outline-none text-black font-inter text-base font-thin w-[80%]"
						type="text"
					/>
					<button className="w-[30%] md:w-[131px] h-[48px] p-[10px 20px] flex-shrink-0 rounded-[50px] bg-[#434343] text-white font-inter text-xs md:text-base font-semibold leading-normal">
						Subscribe{" "}
					</button>
				</div>
			</div>
		</div>
	);
}

export default Query;
