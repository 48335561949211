import React, { useEffect } from "react";
import { ReactComponent as Icon1 } from "../assets/WHY (1).svg";
import { ReactComponent as Icon2 } from "../assets/WHY (2).svg";
import { ReactComponent as Icon3 } from "../assets/WHY (3).svg";
import { ReactComponent as Icon4 } from "../assets/WHY (4).svg";

import AOS from "aos";
import "aos/dist/aos.css";

function Why() {
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<div className="relative overflow-hidden my-[5%] flex flex-col justify-start align-start items-start lg:px-[5%]">
			<div className="pl-[5%]">
				<p
					data-aos="fade-zoom-in"
					data-aos-offset="200"
					data-aos-easing="ease-in-sine"
					data-aos-duration="500"
					className="text-[#434343] font-poppins text-lg font-semibold leading-[170%] tracking-wider uppercase"
				>
					Why Motiwala builders{" "}
				</p>
				<h2
					data-aos="fade-zoom-in"
					data-aos-offset="200"
					data-aos-easing="ease-in-sine"
					data-aos-duration="500"
					className="text-[#434343] font-playfair text-2xl md:text-3xl lg:text-4xl font-bold leading-[140%]"
				>
					Why Choose Motiwala Builders?{" "}
				</h2>

			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-6 py-[5%] px-[5%]">
				{[
					{ Icon: <Icon1 className="w-20 h-20 flex-shrink-0" />, heading: "Rich Heritage and Experience", paragraph: "With over 32 years in the real estate industry, the group has launched more than 57 projects, with 27 completed under the leadership of Mr. Wahid Abdul Qadir Motiwala." },
					{ Icon: <Icon2 className="w-20 h-20 flex-shrink-0" />, heading: "Commitment to Quality", paragraph: "Motiwala Builders is committed to raising the bar for quality housing and commercial projects, ensuring that every development meets stringent quality benchmarks." },
					{ Icon: <Icon3 className="w-20 h-20 flex-shrink-0" />, heading: "Expert Design and Engineering", paragraph: "Recent projects have been designed and engineered by experts from the UAE, incorporating global standards and innovations into their developments." },
					{ Icon: <Icon4 className="w-20 h-20 flex-shrink-0" />, heading: "Customer-Centric Approach", paragraph: "The group’s commitment to customer satisfaction ensures that all projects are designed with the needs and desires of their clients in mind." }
				].map((a, i) => (
					<div
						data-aos="flip-down"
						data-aos-offset="200"
						data-aos-easing="ease-in-sine"
						data-aos-duration="2000"
						className="rounded-xl bg-[#FFF] shadow-[0px 20px 50px 0px rgba(18, 17, 39, 0.08)] flex flex-col px-[24px] py-[56px] gap-6"
						style={{
							boxShadow: "0px 20px 50px 0px rgba(18, 17, 39, 0.08)"
						}}
					>
						{a?.Icon}
						<h6 className="text-[#121127] font-poppins text-base font-bold leading-[140%]">
							{a?.heading}
						</h6>
						<p className="self-stretch text-[rgba(18, 17, 39, 0.56)] font-poppins text-sm font-normal leading-[180%]">
							{a?.paragraph}
						</p>
					</div>
				))}
			</div>
		</div>
	);
}

export default Why;
