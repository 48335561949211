import React, { useEffect } from "react";
import BagroundImage from "../assets/AboutUs.png";
import AOS from "aos";
import "aos/dist/aos.css";
function AboutUs() {
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<div id="About" className="relative overflow-hidden my-[5%] flex flex-col justify-start align-start items-start lg:px-[5%]">
			<div
				data-aos="flip-right"
				className="w-full lg:w-[80%] xl:w-[80%] flex flex-row items-center justify-center bg-no-repeat bg-cover bg-center h-[120vh] md:h-[100vh] lg:h-[130vh] xl:h-[110vh]"
				style={{
					background: `url('${BagroundImage}')`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover"
				}}
			></div>
			<div
				data-aos="fade-zoom-in"
				data-aos-offset="200"
				data-aos-easing="ease-in-sine"
				data-aos-duration="2000"
				className="absolute overflow-hidden right-[5%] top-[5%] w-[90%] lg:w-[40%] xl:w-[37%] px-[3%] py-[1.5%] rounded-sm bg-[#434343] shadow-[0px 8px 8px -4px rgba(17, 24, 39, 0.04), 0px 20px 24px -4px rgba(17, 24, 39, 0.10)]"
			>
				<h4 className="text-white font-work-sans font-bold text-xl md:text-2xl lg:text-3xl">
					MOTIWALA BUILDERS
				</h4>
				<div
					className="overflow-hidden h-[100%]
        scrollbar-w-1 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-thumb-[30px] scrollbar-thumb-[#FFF] scrollbar-track-[#FFF]"
				>
					<p className="my-[10px] text-white font-work-sans md:text-base lg:text-[14px] lg:leading-[18px] text-[14px] leading-[18px]">
						The A.K. <b>Motiwala Group </b>has a rich history dating back to the pre-partition era when they
						established their first jewelery outlet in Karachi's Gold Market in 1960. Founded by Mr. Noor
						Muhammad Jummah <b>Motiwala</b>, the outlet soon became known for its quality, latest designs,
						and customer satisfaction. Today, the group is led by Mr. Wahid Abdul Qadir <b>Motiwala</b>, the
						CEO of <b>Motiwala Builders and Shahnaz Akther & Company</b>, two business arms of the group
						that ventured into real estate development and construction in 1976.
					</p>
					<p className="my-[10px] text-white font-work-sans md:text-base lg:text-[14px] lg:leading-[18px] text-[14px] leading-[18px]">
						<b>Motiwala Builders and Shahnaz Akther & Company</b> were established with a clear mission - to
						raise the bar for quality housing and commercial projects in Karachi, Pakistan. Over the last
						32 years, the group has launched more than 57 projects in strategic locations, including posh
						residential and commercial areas. Of these, 27 have been completed under Mr. Wahid
						Abdul Qadir <b>Motiwala's Leadership</b>, such as <b>Motiwala</b> Mansion, Amna Villas, <b>Motiwala </b>
						Arcade, Bahadurabad Arcade, Al Amna Heights, Z.N Tower, City Avenue, City View & City
						Centre Residency.
					</p>
					<p className="my-[10px] text-white font-work-sans md:text-base lg:text-[14px] lg:leading-[18px] text-[14px] leading-[18px]">
						The group's commitment to excellence has earned them a reputation as a reliable and trust
						worthy builder and developer in Karachi. Their latest projects, Metropolis and Metropolis
						Signature, are a testament to their expertise and professionalism. Designed and engineered
						by experts from the UAE, these projects blend residential and commercial properties to meet
						the needs of modern urban living. The A.K. <b>Motiwala Group's </b>story is one of success, diversification, strong leadership, and excellent team spirit, and they continue to strive towards new
						heights of excellence in the years to come.
					</p>
					<a href="#Projects" className="rounded-md border-2 border-[#434343] bg-[#FFF] inline-flex items-center px-5 py-4 gap-3 text-[#434343] font-work-sans font-semibold md:text-base lg:text-md">
						More on Our History
					</a>
				</div>
			</div>
		</div>
	);
}

export default AboutUs;
