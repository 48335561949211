import React, { useEffect, useState } from "react";
// import Project1 from "../assets/Cam-4-72 dpi_auto_x2_toned 1 (1).png";
// import Project2 from "../assets/Cam-4-72 dpi_auto_x2_toned 1 (2).png";
// import Project3 from "../assets/Cam-4-72 dpi_auto_x2_toned 1 (3).png";
// import Project4 from "../assets/Cam-4-72 dpi_auto_x2_toned 1 (4).png";
// import Project5 from "../assets/Cam-4-72 dpi_auto_x2_toned 1 (5).png";
// import Project6 from "../assets/Cam-4-72 dpi_auto_x2_toned 1 (6).png";

import Project1 from "../assets/Projects/Project (1).jpeg";
import Project2 from "../assets/Projects/Project (2).jpeg";
import Project3 from "../assets/Projects/Project (3).jpeg";
import Project4 from "../assets/Projects/Project (4).jpeg";
import Project5 from "../assets/Projects/Project (5).jpeg";
import Project6 from "../assets/Projects/Project (6).jpeg";
import Project7 from "../assets/Projects/Project (7).jpeg";
import Project8 from "../assets/Projects/Project (8).jpeg";
import Project9 from "../assets/Projects/Project (9).jpeg";
import Project10 from "../assets/Projects/Project (10).jpeg";
import Project11 from "../assets/Projects/Project (11).jpeg";
import Project12 from "../assets/Projects/Project (12).jpeg";
import Project13 from "../assets/Projects/Project (13).jpeg";
import Project14 from "../assets/Projects/Project (14).jpeg";
import Project15 from "../assets/Projects/Project (15).jpeg";
import Project16 from "../assets/Projects/Project (16).jpeg";
import Project17 from "../assets/Projects/Project (17).jpeg";
import Project18 from "../assets/Projects/Project (18).jpeg";

import { ReactComponent as Home1 } from "../assets/Home (1).svg";
import { ReactComponent as Home2 } from "../assets/Home (2).svg";
import { ReactComponent as Home3 } from "../assets/Home (3).svg";
import { ReactComponent as Home4 } from "../assets/Home (4).svg";
import { ReactComponent as Home5 } from "../assets/Home (5).svg";
import { ReactComponent as Home6 } from "../assets/Home (6).svg";
import { ReactComponent as Home7 } from "../assets/Home (7).svg";
import { ReactComponent as Home8 } from "../assets/Home (8).svg";

import AOS from "aos";
import "aos/dist/aos.css";
function Pinnacale() {
	const [PropertyType, setPropertyType] = useState("All")
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<div id="Services" className={`${(!PropertyType || PropertyType == "") && "h-[100vh]"} relative overflow-hidden my-[5%] flex flex-col justify-center align-center items-center lg:px-[5%]`}>
			<h1
				data-aos="fade-down"
				data-aos-offset="200"
				data-aos-easing="ease-in-sine"
				data-aos-duration="100"
				className="text-[#434343] text-center font-playfair text-3xl md:text-4xl lg:text-5xl font-semibold leading-[188.235%]"
			>
				Pinnacle Creations{" "}
			</h1>
			{PropertyType && <div className="grid grid-cols-6 my-[5%] mx-[5%]">
				{[
					{ Image: <Home8 />, Name: "All" },
					{ Image: <Home1 />, Name: "Commercial Property" },
					{ Image: <Home2 />, Name: "Hospitality Property" },
					{ Image: <Home4 />, Name: "Mixed-Use Development" },
					{ Image: <Home5 />, Name: "Specialized Facility" },
					{ Image: <Home6 />, Name: "Residential Property" },
				].map((a, i) => (
					<div
						className={`flex flex-col items-center w-[100px] p-[10px 16px] gap-2 cursor-pointer`}
						data-aos="zoom-in"
						data-aos-offset="200"
						data-aos-easing="ease-in-sine"
						data-aos-duration="1000"
						onClick={() => {
							setPropertyType("")
							setTimeout(() => {
								setPropertyType(a?.Name)
							}, 10);
						}}
					>
						{a?.Image}
						<p
							data-aos="fade-down"
							data-aos-offset="200"
							data-aos-easing="ease-in-sine"
							data-aos-duration="1000"
							className={`${a?.Name == PropertyType ? "text-primary font-bold" : "text-[#565656] font-thin"} text-center font-inter text-xs md:text-sm  leading-normal`}
						>
							{a?.Name}
						</p>
					</div>
				))}
			</div>}
			{PropertyType && (
				<div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2  xl:grid-cols-2  gap-10 mb-[5%] mx-[5%]"
					data-aos="zoom-out-left"
					data-aos-offset="200"
					data-aos-easing="ease-in-sine"
					data-aos-duration="2000"
				>
					{[
						{
							"background": Project1,
							"Type": "Completed",
							"Heading": "NICE TRADE ORBIT",
							"Text": "Nice Trade Orbit is a dynamic project situated on Shahrah-e-Faisal in Karachi, dedicated to offering premier office spaces and showrooms. With a project cost of 2100 million, it provides modern facilities and strategic location advantages, making it an ideal choice for businesses looking to establish a prominent presence in Karachi's commercial hub.",
							"Category": "Commercial Property"
						},
						{
							"background": Project2,
							"Type": "Completed",
							"Heading": "DUBAI PALACE (GUEST HOUSE)",
							"Text": "Dubai Palace (Guest House) in Gulistan-e-Johar, Karachi, offers a unique hospitality experience. With a project cost of AED 1,780,120, it provides luxurious accommodation and premium amenities, reflecting a blend of comfort and elegance. Ideal for travelers seeking a distinctive stay in Karachi, Dubai Palace promises an exceptional level of service and convenience.",
							"Category": "Hospitality Property"
						},
						{
							"background": Project3,
							"Type": "Completed",
							"Heading": "PARKING & STORE SHED",
							"Text": "This project in Gulistan-e-Johar, Karachi, designed as part of Dubai Palace, emphasizes efficient parking solutions and storage facilities. With a project cost of AED 10,887,791, it ensures secure and convenient parking options alongside spacious storage sheds. Ideal for enhancing the operational efficiency and convenience of Dubai Palace, this development caters to both residents and visitors with modern amenities and strategic design.",
							"Category": "Specialized Facility"
						},
						{
							"background": Project4,
							"Type": "Completed",
							"Heading": "AL-AMNA HEIGHTS",
							"Text": "Located on Khalid Bin Waleed Road in Karachi, Al-Amna Heights is a vibrant mixed-use development. With a project cost of 839 million, it offers a blend of residential units and showroom spaces. Designed for comfort and convenience, Al-Amna Heights provides modern amenities and a strategic location, making it an ideal choice for residents and businesses seeking quality living and commercial opportunities in Karachi.",
							"Category": "Mixed-Use Development"
						},
						{
							"background": Project5,
							"Type": "Completed",
							"Heading": "CITI CENTRE RESIDENCY",
							"Text": "City Centre Residency, nestled in Bahadurabad, Karachi, offers a seamless blend of commercial and residential spaces. With a project cost of 2200 million, it provides modern living accommodations alongside convenient commercial facilities. Designed to enhance urban lifestyles, City Centre Residency promises a vibrant community atmosphere and strategic accessibility, catering to the diverse needs of its residents and businesses alike.",
							"Category": "Mixed-Use Development"
						},
						{
							"background": Project6,
							"Type": "Completed",
							"Heading": "Z.N. TOWERS",
							"Text": "Z.N. Towers, situated on Alamgir Road in Karachi, offers a blend of residential living and showroom spaces. With a project cost of 480 million, it provides modern amenities and convenient access to commercial facilities. Designed for comfort and functionality, Z.N. Towers is an ideal choice for those seeking quality residential accommodations and business opportunities in Karachi's vibrant landscape.",
							"Category": "Mixed-Use Development"
						},
						{
							"background": Project7,
							"Type": "Completed",
							"Heading": "CITI AVENUE",
							"Text": "City Avenue, situated on Shahrah-e-Faisal in Karachi, is a prestigious development offering modern office spaces and showroom facilities. With a project cost of 931 million, it combines state-of-the-art infrastructure with strategic location advantages, making it an ideal choice for businesses looking to thrive in Karachi's bustling commercial hub.",
							"Category": "Commercial Property"
						},
						{
							"background": Project8,
							"Type": "Completed",
							"Heading": "CITI HEIGHTS",
							"Text": "City Heights, located on Shahrah-e-Faisal in Karachi, is a versatile development offering both residential living and showroom spaces. With a project cost of 2400 million, it provides a blend of modern amenities and convenient commercial facilities. Designed to meet the needs of urban living and business, City Heights promises comfort, convenience, and a strategic location in Karachi's vibrant landscape.",
							"Category": "Mixed-Use Development"
						},
						{
							"background": Project9,
							"Type": "Completed",
							"Heading": "MILLENNIUM MALL",
							"Text": "Millennium Mall, situated on Rashid Minhas Road in Karachi, is a premier shopping destination. With a project cost of 3000 million, it offers a diverse range of retail outlets, dining options, and entertainment facilities. Designed to provide a modern shopping experience, Millennium Mall caters to the diverse preferences of shoppers and stands as a landmark in Karachi's retail landscape.",
							"Category": "Commercial Property"
						},
						{
							"background": Project10,
							"Type": "Completed",
							"Heading": "EROS COMPLEX",
							"Text": "Eros Complex, situated on M.A. Jinnah Road in Karachi, offers a unique blend of residential and commercial spaces. With a project cost of 930 million, it provides modern living accommodations alongside vibrant commercial opportunities. Designed to cater to diverse lifestyles, Eros Complex promises convenience, accessibility, and a dynamic urban environment in the heart of Karachi.",
							"Category": "Mixed-Use Development"
						},
						{
							"background": Project11,
							"Type": "Completed",
							"Heading": "FEROZA HEIGHTS",
							"Text": "Feroza Heights, located in Bahadurabad, Karachi, is a residential project aimed at providing comfortable and affordable living spaces. With a project cost of 90 million, it offers modern amenities and a convenient location, making it an ideal choice for individuals looking for quality residential accommodations in Karachi.",
							"Category": "Residential Property"
						},
						{
							"background": Project12,
							"Type": "Completed",
							"Heading": "AL-AMNA TOWN HOUSES",
							"Text": "Al-Amna Town Houses, situated on Razi Road in Karachi, offers contemporary residential living. With a project cost of 260 million, it provides modern amenities and a comfortable living environment. Designed for convenience and affordability, Al-Amna Town Houses are ideal for those seeking quality residential options in Karachi's urban setting.",
							"Category": "Residential Property"
						},
						{
							"background": Project13,
							"Type": "Completed",
							"Heading": "BELAIR LUXURY RESORT",
							"Text": "Belair Luxury Resort, located on the Super Highway in Karachi, is a prestigious resort destination. With a project cost of 9000 million, it offers unparalleled luxury, exquisite amenities, and breathtaking views. Designed to provide a serene escape from the city, Belair Luxury Resort promises an unforgettable experience for leisure travelers and discerning guests looking for a retreat in Karachi.",
							"Category": "Hospitality Property"
						},
						{
							"background": Project14,
							"Type": "Completed",
							"Heading": "AL-AMNA VILLAS",
							"Text": "Al-Amna Villas, nestled in Hill Park, Karachi, offer charming residential living in a serene environment. With a project cost of 80 million, these villas provide modern amenities and a tranquil setting, ideal for families or individuals seeking a peaceful yet convenient lifestyle in Karachi.",
							"Category": "Residential Property"
						},
						{
							"background": Project15,
							"Type": "Completed",
							"Heading": "BAHADURABAD ARCADE",
							"Text": "Bahadurabad Arcade, situated in the heart of Bahadurabad, Karachi, is a vibrant commercial hub featuring showrooms and commercial spaces. With a project cost of 450 million, it offers modern amenities and a strategic location, making it an ideal choice for businesses looking to establish a prominent presence in Karachi's commercial landscape.",
							"Category": "Commercial Property"
						},
						{
							"background": Project16,
							"Type": "Completed",
							"Heading": "AL-AMNA TOWER",
							"Text": "Al-Amna Tower, located on Tariq Road in Karachi, offers modern residential living with a focus on comfort and convenience. With a project cost of 430 million, it provides contemporary amenities and a prime location, making it an attractive choice for those seeking quality residential accommodations in Karachi's bustling urban environment.",
							"Category": "Residential Property"
						},
						{
							"background": Project17,
							"Type": "Completed",
							"Heading": "AL-RAHIMA GARDEN",
							"Text": "Al-Rahima Garden, nestled in Garden East, Karachi, offers affordable and comfortable residential living. With a project cost of 150 million, it provides modern amenities and a convenient location, making it an ideal choice for individuals and families looking for quality residential accommodations in Karachi.",
							"Category": "Residential Property"
						},
						{
							"background": Project18,
							"Type": "Completed",
							"Heading": "CITI CLUB",
							"Text": "Citi Club, located on Khalid Bin Waleed Road in Karachi, offers a blend of residential living and showroom spaces. With a project cost of 660 million, it provides modern amenities and a strategic location, catering to both residential needs and commercial ventures in Karachi's dynamic urban landscape.",
							"Category": "Mixed-Use Development"
						}
					].filter(a => {
						if (PropertyType == "All") return a
						else if (a?.Category == PropertyType) {
							return a
						}
					})
						.map((a, i) => (
							<div
								className={
									"gap-4 h-[40vh] xs:h-[40vh] md:h-[50vh]  w-full flex flex-col bg-no-repeat bg-cover rounded-[20px] md:rounded-[50px] p-[20px] xl:p-[40px] lg:p-[40px] md:p-[20px] sm:p-[10px]  bg-lightgray "
								}
								style={{
									backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('${a?.background}')`,
									backgroundRepeat: "no-repeat",
									backgroundSize: "cover"
								}}
							>
								<p
									data-aos="fade-up"
									data-aos-offset="200"
									data-aos-easing="linear"
									data-aos-duration="1000"
									
									className={
										"text-[25px] font-semibold leading-[30px] text-white font-poppins "
									}
								>
									{a?.Heading}
								</p>
								<p
									data-aos="fade-up"
									data-aos-offset="200"
									data-aos-easing="linear"
									data-aos-duration="1000"
									
									className={
										"text-sm sm:text-xs md:text-sm font-normal text-white font-poppins "
									}
								>
									{a?.Text}
								</p>
								<p
									data-aos="fade-up"
									data-aos-offset="200"
									data-aos-easing="linear"
									data-aos-duration="1000"
									
									className="bg-[#000] px-[10px] py-[5px] rounded-[10px] w-[fit-content] overflow-hidden text-white font-poppins text-[18px] font-bold"
								>
									{a?.Type.toUpperCase()}
								</p>
							</div>
						))}
				</div>
			)}
		</div>
	);
}

export default Pinnacale;
