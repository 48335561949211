import React, { useEffect } from "react";
import Image from "../assets/BG.png";
import Logo from "../assets/Logo.png";
import Logo1 from "../assets/image-removebg-preview (27) 1.png";
import Logo2 from "../assets/image-removebg-preview (28) 1.png";
import Logo3 from "../assets/image-removebg-preview (29) 1.png";

import AOS from "aos";
import "aos/dist/aos.css";

function Authorities() {
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<div
			data-aos="fade-up"
			data-aos-offset="200"
			data-aos-easing="ease-in-sine"
			data-aos-duration="1000"
			className="relative overflow-hidden py-[2%] flex flex-col md:flex-row lg: xl:flex-row justify-center align-center items-center gap-0 h-[100vh] md:h-[70vh] xl:h-[50vh]  lg:h-[50vh]
		bg-[#434343]
		"
		>
			<img
				data-aos="fade-down"
				data-aos-offset="200"
				data-aos-easing="ease-in-sine"
				data-aos-duration="1000"
				className="lg:w-[15%] lg:h-[50%] md:w-[30%] md:h-[50%] xs:w-[50%] xs:h-[100%] sm:w-[40%] sm:h-[100%] object-fill px-[5%] md:px-[0%] lg:px-[0%] mx-[5%] my-[5%]"
				src={Logo}
			/>{" "}
			<div
				data-aos="fade-up"
				data-aos-offset="200"
				data-aos-easing="ease-in-sine"
				data-aos-duration="1000"
				className="w-[80%] place-items-center grid grid-cols-3 gap-4 bg-no-repeat bg-cover bg-center h-[100%] px-[10px] xl:px-[80px] lg:px-[80px] md:px-[10px] sm:px-[20px]"
				style={{
					background: `url('${Image}')`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover"
				}}
			>
				{[
					Logo1,
					Logo2,
					Logo3,
				].map((a) => (
					<img
						data-aos="zoom-in"
						data-aos-offset="200"
						data-aos-easing="ease-in-sine"
						data-aos-duration="1000"
						className="w-full object-fill md:object-none"
						src={a}
					/>
				))}
			</div>
		</div>
	);
}

export default Authorities;
